<template>
  <div class="flex justify-center items-center" style="height: 100vh;">
    <el-empty description="您的屏幕太小了，考试需要屏幕宽度大于 768px 的平板或 PC 设备。">
      <el-button type="primary" plain icon="el-icon-back" @click="$router.push('/exam/center')">返回考试中心</el-button>
    </el-empty>
  </div>
</template>

<script>
export default {
  name: 'NeedLaptop'
}
</script>